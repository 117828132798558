<template lang="pug">
	.flex.font-bold.justify-center.h-screen.items-center.h-full.p-2.text-3xl.lg--text-6xl.uppercase(:class="'text-gray-'+invertScrollClass+'00'")
		div.p-8
			div Soluções digitais
			div pensadas especificamente
			div para o seu negócio
</template>

<script>
export default {
  name: "Home",
  props: ["invertScrollClass"],
};
</script>

<style scoped></style>
