<template lang="pug">
  //- .color-transition.fixed.flex.justify-between.p-4.top-0.w-full.z-20(:class="'bg-gray-' + scrollClass + '00'")
  //-   logo(:class="'text-gray-' + invertScrollClass + '00'")
  //-   .lg--flex.font-bold.items-center.text-xl.text-transition.hidden(:class="'text-gray-' + invertScrollClass + '00'")
  //-     a.px-2.text-md.font-medium.ml-2.active--underline(href="#mobile") Apps Mobile
  //-     a.px-2.text-md.font-medium.ml-2.active--underline(href="#software") Software Web
  //-     a.px-2.text-md.font-medium.ml-2.active--underline(href="#marketing") Marketing
  //-     a.px-2.text-md.font-medium.ml-2.active--underline(href="#contatos") Contatos
  //-   .flex.justify-between.items-center.lg--hidden(@click="opened= true" :class="'text-gray-' + invertScrollClass + '00'")
  //-     a.mr-4.underline.uppercase(:href="activeContainer.link") {{activeContainer.name}}
  //-     svg.fill-current.w-4(version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 330 330' style='enable-background:new 0 0 330 330;' xml:space='preserve')
  //-       path#XMLID_225_(d='M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393\
  //-       c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393\
  //-       s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z')
  //-   .flex.justify-center.items-center.fixed.h-screen.w-full.z-10.bg-opacity-90.inset-0(v-if="opened"  @click="opened = false" :class="'bg-gray-' + invertScrollClass + '00'")
  //-     div(:class="'text-gray-' + scrollClass + '00'")
  //-       a.mb-8.font-bold.block.px-2.text-3xl.underline.uppercase(href="#software") Software Web
  //-       a.mb-8.font-bold.block.px-2.text-3xl.underline.uppercase(href="#mobile") Apps Móveis
  //-       a.mb-8.font-bold.block.px-2.text-3xl.underline.uppercase(href="#marketing") Marketing
  //-       a.mb-8.font-bold.block.px-2.text-3xl.underline.uppercase(href="#contatos") Contatos

  .fixed.w-full(:class="'bg-gray-' + scrollClass + '00'").z-20
    .flex.justify-center.p-4.w-full
      logo(:class="'text-gray-' + invertScrollClass + '00'")
    //- .flex.justify-center.text-xl
    //-   a.px-2.text-md.font-medium.ml-2.active--underline(href="#mobile") Apps Mobile
    //-   a.px-2.text-md.font-medium.ml-2.active--underline(href="#software") Software Web
    //-   a.px-2.text-md.font-medium.ml-2.active--underline(href="#marketing") Marketing
    //-   a.px-2.text-md.font-medium.ml-2.active--underline(href="#contatos") Contatos
  
  
    
</template>

<script>
import Logo from "./Logo.vue";

export default {
  name: "Navbar",
  components: {
    Logo,
  },
  props: ["scrollClass", "invertScrollClass", "scrollY"],
  data() {
    return {
      opened: false,
    };
  },
  watch: {
    opened(val) {
      if (val) {
        document.documentElement.style.overflow = "hidden";
        return;
      }

      document.documentElement.style.overflow = "auto";
    },
  },
  computed: {
    activeContainer() {
      // if (this.scrollY >= 0.2 && this.scrollY < 0.4) {
      //   return {
      //     link: "#software",
      //     name: "Software Web",
      //   };
      // } else if (this.scrollY >= 0.4 && this.scrollY < 0.6) {
      //   return {
      //     link: "#mobile",
      //     name: "Apps Móveis",
      //   };
      // } else if (this.scrollY >= 0.6 && this.scrollY < 0.8) {
      //   return {
      //     link: "#marketing",
      //     name: "Marketing",
      //   };
      // } else if (this.scrollY >= 0.8) {
      //   return {
      //     link: "#contatos",
      //     name: "Contatos",
      //   };
      // } else {
      //   return {
      //     link: "#main",
      //     name: "",
      //   };
      // }

      if (this.scrollY >= 0.8) {
        return {
          link: "#contatos",
          name: "Contatos",
        };
      } else {
        return {
          link: "#main",
          name: "",
        };
      }
    },
  },
};
</script>

<style scoped></style>
