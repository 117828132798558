<template lang="pug">
	.flex.justify-center.h-screen.items-center.h-full.p-2.text-gray-200.uppercase(:class="'text-gray-'+invertScrollClass+'00'")
		div
			div.text-3xl.mb-24.text-center Uma nova imagem brevemente...
			div.flex.justify-center
				div
					div M: Praceta São Pedro, 41 r/c
					div 4400-584 Vila Nova de Gaia
					div Porto
					div.mt-8 T: 91 007 50 59
					div.mt-8 E: geral@iterari.pt


</template>

<script>
export default {
  name: "Contacts",
  props: ["invertScrollClass"],
};
</script>

<style scoped></style>
