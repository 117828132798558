<template>
  <div class="flex justify-center items-center h-screen">
      <h1 class="text-5xl text-center">Estamos a melhorar o nosso website</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>