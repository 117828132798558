<template>
  <div class="flex justify-center items-center min-h-screen h-full lg--px-12 xl--px-24 pt-32" :class="'text-gray-' + invertScrollClass + '00'">
    <div class="lg--flex w-full">
      <div class="justify-center items-center lg--w-1/2 hidden lg--flex">
        <svg
          aria-hidden="true"
          focusable="false"
          data-prefix="fas"
          data-icon="mobile"
          class="svg-inline--fa fa-mobile fa-w-10 h-48 lg--h-96"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
        >
          <path
            fill="currentColor"
            d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zM160 480c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"
          ></path>
        </svg>
      </div>
      <div class="flex justify-center items-center lg--w-1/2 mt-16 lg--mt-0">
        <div class="p-8">
          <h1 class="font-bold text-center text-3xl mb-6 lg--mb-16">Apps Mobile</h1>
          <p class="text-xl font-bold">Teve uma ideia para uma aplicação? E agora o que fazer?</p>
          <p class="text-lg">
            Através da nossa equipa multidisciplinar podemos ajudar em todos os passos do processo de criação de uma aplicação, desde a ideia inicial até ao lançamento e
            promoção da mesma. Os nossos engenheiros e designers vão ajudar a construir uma aplicação mobile com o UX/UI perfeito para que a experiencia do utilizador
            seja o mais agradável e apelativa possível, aproveitando ao máximo os recursos do equipamento - telemóvel ou tablet. As nossa aplicações nativas são
            desenvolvidas para sistemas operativos Android e IOS.
          </p>
          <div class="flex justify-around mt-16">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="android"
              class="svg-inline--fa fa-android fa-w-18 w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M420.55,301.93a24,24,0,1,1,24-24,24,24,0,0,1-24,24m-265.1,0a24,24,0,1,1,24-24,24,24,0,0,1-24,24m273.7-144.48,47.94-83a10,10,0,1,0-17.27-10h0l-48.54,84.07a301.25,301.25,0,0,0-246.56,0L116.18,64.45a10,10,0,1,0-17.27,10h0l47.94,83C64.53,202.22,8.24,285.55,0,384H576c-8.24-98.45-64.54-181.78-146.85-226.55"
              ></path>
            </svg>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="apple"
              class="svg-inline--fa fa-apple fa-w-12 w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
            >
              <path
                fill="currentColor"
                d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Software",
  props: ["invertScrollClass"],
};
</script>

<style scoped></style>
