<template>
  <a href="#main">
    <svg class="fill-current w-32 lg--w-48" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 571.41 99.83">
      <title>1</title>
      <rect class="cls-1" y="1.79" width="14.02" height="14.02" />
      <rect class="cls-1" x="8.65" y="15.81" width="14.02" height="14.02" />
      <rect class="cls-1" x="15.66" y="29.69" width="14.02" height="14.02" />
      <rect class="cls-1" x="25.6" y="43.71" width="14.02" height="14.02" />
      <rect class="cls-1" x="15.66" y="57.73" width="14.02" height="14.02" />
      <rect class="cls-1" x="8.65" y="71.75" width="14.02" height="14.02" />
      <rect class="cls-1" y="85.81" width="14.02" height="14.02" />
      <rect class="cls-1" x="77.56" y="7.37" width="14.02" height="14.02" />
      <rect class="cls-1" x="65.34" y="29.28" width="14.02" height="14.02" />
      <rect class="cls-1" x="77.25" y="29.28" width="14.02" height="14.02" />
      <rect class="cls-1" x="77.34" y="43.3" width="14.02" height="14.02" />
      <rect class="cls-1" x="77.34" y="56.62" width="14.02" height="14.02" />
      <rect class="cls-1" x="77.34" y="70.5" width="14.02" height="14.02" />
      <rect class="cls-1" x="64.35" y="82.85" width="14.02" height="14.02" />
      <rect class="cls-1" x="77.34" y="82.85" width="14.02" height="14.02" />
      <rect class="cls-1" x="90.77" y="82.85" width="14.02" height="14.02" />
      <rect class="cls-1" x="63.85" y="29.28" width="13.4" height="13.4" />
      <rect class="cls-1" x="136.62" width="14.02" height="14.02" />
      <rect class="cls-1" x="136.62" y="14.02" width="14.02" height="14.02" />
      <rect class="cls-1" x="122.6" y="26.23" width="14.02" height="14.02" />
      <rect class="cls-1" x="136.62" y="26.23" width="14.02" height="14.02" />
      <rect class="cls-1" x="122.6" y="26.23" width="14.02" height="14.02" />
      <rect class="cls-1" x="150.64" y="26.23" width="14.02" height="14.02" />
      <rect class="cls-1" x="136.62" y="40.25" width="14.02" height="14.02" />
      <rect class="cls-1" x="136.62" y="54.27" width="14.02" height="14.02" />
      <rect class="cls-1" x="136.62" y="68.29" width="14.02" height="14.02" />
      <rect class="cls-1" x="143.63" y="82.32" width="14.02" height="14.02" />
      <rect class="cls-1" x="157.65" y="82.32" width="14.02" height="14.02" />
      <rect class="cls-1" x="197.15" y="27.19" width="14.02" height="14.02" />
      <rect class="cls-1" x="211.17" y="27.19" width="14.02" height="14.02" />
      <rect class="cls-1" x="225.19" y="27.19" width="14.02" height="14.02" />
      <rect class="cls-1" x="193.83" y="41.21" width="14.02" height="14.02" />
      <rect class="cls-1" x="227.96" y="41.21" width="14.02" height="14.02" />
      <rect class="cls-1" x="189.42" y="55.23" width="14.02" height="14.02" />
      <rect class="cls-1" x="203.44" y="55.23" width="14.02" height="14.02" />
      <rect class="cls-1" x="217.46" y="55.23" width="14.02" height="14.02" />
      <rect class="cls-1" x="231.49" y="55.23" width="14.02" height="14.02" />
      <rect class="cls-1" x="193.83" y="69.25" width="14.02" height="14.02" />
      <rect class="cls-1" x="199.84" y="83.27" width="14.02" height="14.02" />
      <rect class="cls-1" x="213.86" y="83.27" width="14.02" height="14.02" />
      <rect class="cls-1" x="227.88" y="83.27" width="14.02" height="14.02" />
      <rect class="cls-1" x="256.63" y="27.19" width="14.02" height="14.02" />
      <rect class="cls-1" x="276.04" y="27.19" width="14.02" height="14.02" />
      <rect class="cls-1" x="290.06" y="27.19" width="14.02" height="14.02" />
      <rect class="cls-1" x="292.8" y="41.26" width="14.02" height="14.02" />
      <rect class="cls-1" x="264.83" y="41.21" width="14.02" height="14.02" />
      <rect class="cls-1" x="264.83" y="55.23" width="14.02" height="14.02" />
      <rect class="cls-1" x="264.83" y="69.25" width="14.02" height="14.02" />
      <rect class="cls-1" x="256.63" y="83.27" width="14.02" height="14.02" />
      <rect class="cls-1" x="270.65" y="83.27" width="14.02" height="14.02" />
      <rect class="cls-1" x="385.79" y="27.39" width="14.02" height="14.02" />
      <rect class="cls-1" x="405.2" y="27.39" width="14.02" height="14.02" />
      <rect class="cls-1" x="419.22" y="27.39" width="14.02" height="14.02" />
      <rect class="cls-1" x="421.96" y="41.46" width="14.02" height="14.02" />
      <rect class="cls-1" x="393.99" y="41.41" width="14.02" height="14.02" />
      <rect class="cls-1" x="393.99" y="55.43" width="14.02" height="14.02" />
      <rect class="cls-1" x="393.99" y="69.45" width="14.02" height="14.02" />
      <rect class="cls-1" x="385.79" y="83.47" width="14.02" height="14.02" />
      <rect class="cls-1" x="399.81" y="83.47" width="14.02" height="14.02" />
      <rect class="cls-1" x="469.88" y="8.04" width="14.02" height="14.02" />
      <rect class="cls-1" x="457.65" y="29.95" width="14.02" height="14.02" />
      <rect class="cls-1" x="469.56" y="29.95" width="14.02" height="14.02" />
      <rect class="cls-1" x="469.66" y="43.97" width="14.02" height="14.02" />
      <rect class="cls-1" x="469.66" y="57.29" width="14.02" height="14.02" />
      <rect class="cls-1" x="469.66" y="71.17" width="14.02" height="14.02" />
      <rect class="cls-1" x="456.67" y="83.52" width="14.02" height="14.02" />
      <rect class="cls-1" x="469.66" y="83.52" width="14.02" height="14.02" />
      <rect class="cls-1" x="483.08" y="83.52" width="14.02" height="14.02" />
      <rect class="cls-1" x="456.17" y="29.95" width="13.4" height="13.4" />
      <rect class="cls-1" x="515.33" y="83.52" width="14.02" height="14.02" />
      <rect class="cls-1" x="529.35" y="83.52" width="14.02" height="14.02" />
      <rect class="cls-1" x="543.37" y="83.52" width="14.02" height="14.02" />
      <rect class="cls-1" x="557.39" y="83.52" width="14.02" height="14.02" />
      <rect class="cls-1" x="325.03" y="27.14" width="14.02" height="14.02" />
      <rect class="cls-1" x="339.05" y="27.14" width="14.02" height="14.02" />
      <rect class="cls-1" x="353" y="27.14" width="14.02" height="14.02" />
      <rect class="cls-1" x="353" y="41.16" width="14.02" height="14.02" />
      <rect class="cls-1" x="353" y="55.23" width="14.02" height="14.02" />
      <rect class="cls-1" x="338.97" y="55.23" width="14.02" height="14.02" />
      <rect class="cls-1" x="324.95" y="55.23" width="14.02" height="14.02" />
      <rect class="cls-1" x="317.94" y="69.25" width="14.02" height="14.02" />
      <rect class="cls-1" x="353" y="69.3" width="14.02" height="14.02" />
      <rect class="cls-1" x="326.3" y="83.12" width="14.02" height="14.02" />
      <rect class="cls-1" x="340.32" y="83.12" width="14.02" height="14.02" />
      <rect class="cls-1" x="358.88" y="83.12" width="14.02" height="14.02" />
    </svg>
  </a>
</template>

<script>
export default {
  name: "Logo",
};
</script>

<style></style>
