<template lang="pug">
	div
		//- scrollbar(
		//- 	:invert-scroll-class="invertScrollClass"
		//- 	:scroll-y="scrollY"
		//- 	@positionChanged="changePosition($event)")
		canvas.fixed#nokey
		.color-transition.w-full(:class="'bg-gray-' + scrollClass + '00'")
			navbar(
				:scroll-class="scrollClass"
				:scroll-y="scrollY"
				:invert-scroll-class="invertScrollClass")
			div.z-10.relative
				//- home#main(:invert-scroll-class="invertScrollClass")
				//- software#software(:invert-scroll-class="invertScrollClass")
				//- apps#mobile(:invert-scroll-class="invertScrollClass")
				//- marketing#marketing(:invert-scroll-class="invertScrollClass")
				contacts#contatos(:invert-scroll-class="invertScrollClass")
</template>

<script>
import * as ballAnimation from "./helpers/ballAnimation";
import Home from "./components/Home.vue";
import Contacts from "./components/Contacts.vue";
import Navbar from "./components/Navbar.vue";
import Services from "./components/Services.vue";
import Marketing from "./components/Marketing.vue";
import Software from "./components/Software.vue";
import Apps from "./components/Apps.vue";
import Scrollbar from "./components/Scrollbar.vue";
import Developing from "./components/Developing.vue";

export default {
  name: "app",
  components: {
    Home,
    Contacts,
    Navbar,
    Marketing,
    Apps,
    Software,
    Services,
    Scrollbar,
    Developing,
  },
  data() {
    return {
      scrollClass: 1,
      invertScrollClass: 9,
      scrollY: 0,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    ballAnimation.start();
  },
  watch: {
    scrollClass(val) {
      if (val < 4) {
        this.invertScrollClass = 9;
      } else if (val >= 4 && val <= 5) {
        this.invertScrollClass = 8;
      } else if (val >= 6 && val <= 7) {
        this.invertScrollClass = 2;
      } else {
        this.invertScrollClass = 1;
      }
    },
  },
  methods: {
    changePosition(event) {
      window.scrollTo(0, (event / window.innerHeight) * (document.body.scrollHeight - window.innerHeight));
    },
    handleScroll() {
      var h = document.documentElement,
        b = document.body,
        st = "scrollTop",
        sh = "scrollHeight";

      this.scrollY = Math.floor(h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight);
      let scrollClass = Math.ceil(this.scrollY * 10);

      this.scrollClass = scrollClass;
    },
  },
};
</script>

<style>
@media (min-width: 1024px) {
  body::-webkit-scrollbar {
    display: none;
  }
}

.color-transition {
  transition: background-color 0.3s ease;
}

.text-transition {
  transition: color 0.3s ease;
}

.logo {
  font-family: "Fredoka One", cursive;
}
</style>
