<template>
  <div class="flex justify-center items-center min-h-screen h-full lg--px-12 xl--px-24 pt-32" :class="'text-gray-' + invertScrollClass + '00'">
    <div class="lg--flex w-full">
      <div class="flex justify-center items-center lg--w-1/2">
        <div class="p-8">
          <h1 class="font-bold text-center text-3xl mb-6 lg--mb-16">Software Web</h1>
          <ul class="text-xl">
            <li class="mt-4">
              <p class="font-bold">Software personalizado</p>
              <ul class="ml-4">
                <li>
                  <span class="font-bold text-xl">></span>
                  Soluções de Software desenhadas e desenvolvidas através da colaboração entre os nossos engenheiros e a sua empresa.
                </li>
                <li>
                  <span class="font-bold text-xl">></span>
                  Aumente a produtividade do seu negócio, com uma solução de Software única e sem licenças associadas.
                </li>
                <li>
                  <span class="font-bold text-xl">></span>
                  Personalizável ao longo do tempo, ajustando-se aos requisitos inerentes ao crescimento do seu negócio.
                </li>
              </ul>
            </li>
            <li class="mt-4">
              <p class="font-bold">E-commerce</p>
              <ul class="ml-4">
                <li>
                  <span class="font-bold text-xl">></span>
                  Lojas online 100% personalizadas
                </li>
                <li>
                  <span class="font-bold text-xl">></span>
                  Integração com as plataformas de Marketing digital
                </li>
                <li>
                  <span class="font-bold text-xl">></span>
                  Interface gráfica - estatísticas de analise de performance
                </li>
              </ul>
            </li>
            <li class="mt-4">
              <p class="font-bold">Websites</p>
              <ul class="ml-4">
                <li>
                  <span class="font-bold text-xl">></span>
                  Críamos uma presença online através de websites e landing pages 100% personalizados e responsivos em PC e Mobile.
                </li>
                <li>
                  <span class="font-bold text-xl">></span>
                  Integração com as plataformas de Marketing digital
                </li>
                <li>
                  <span class="font-bold text-xl">></span>
                  Interface gráfica - estatísticas de analise de performance
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex justify-center items-center lg--w-1/2 mt-16 lg--mt-0">
        <svg class="h-48 lg--h-96" version="1.1" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <g id="layer1">
            <path
              d="M 0 2 L 0 18 L 20 18 L 20 2 L 0 2 z M 1 3 L 19 3 L 19 5 L 1 5 L 1 3 z M 1 6 L 19 6 L 19 17 L 8 17 L 8 15 L 2 15 L 2 17 L 1 17 L 1 6 z M 2 8 L 2 14 L 8 14 L 8 8 L 2 8 z M 10 8 L 10 9 L 15 9 L 15 8 L 10 8 z M 3 9 L 7 9 L 7 13 L 3 13 L 3 9 z M 10 10 L 10 11 L 17 11 L 17 10 L 10 10 z M 10 12 L 10 13 L 13 13 L 13 12 L 10 12 z M 10 15 L 10 16 L 15 16 L 15 15 L 10 15 z M 3 16 L 7 16 L 7 17 L 3 17 L 3 16 z "
              style="fill: #222222; fill-opacity: 1; stroke: none; stroke-width: 0px"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Software",
  props: ["invertScrollClass"],
};
</script>

<style scoped></style>
