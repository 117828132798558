<template lang="pug">
	.h-screen.h-full.pt-32 teste
</template>

<script>
    export default {
        name: "Services"
    }
</script>

<style scoped>

</style>