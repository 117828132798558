<template>
  <div class="flex justify-center items-center min-h-screen h-full lg--px-12 xl--px-24 pt-32" :class="'text-gray-' + invertScrollClass + '00'">
    <div>
      <h1 class="font-bold text-center text-3xl mb-6 lg--mb-32">Marketing</h1>
      <div class="lg--flex">
        <div class="p-8 lg--w-1/3 mt-12">
          <div class="flex justify-center items-center mb-16">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="envelope"
              class="svg-inline--fa fa-envelope fa-w-16 h-48 lg--h-64"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
              ></path>
            </svg>
          </div>
          <h3 class="font-bold pb-4 text-xl text-center">Email Marketing</h3>
          <p class="text-justify">
            Oferecemos uma estratégia de Marketing Digital que se baseia no envio de emails para um determinado público, proveniente de uma base de dados de contatos
            (clientes) ou leads gerados através de outras estratégias. Mostre ao seu público as melhores ofertas e oportunidades que os seus produtos ou serviços possam
            oferecer através de e-mail instrutivo.
          </p>
        </div>
        <div class="p-8 lg--w-1/3 mt-12">
          <div class="flex justify-center items-center mb-16">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="users"
              class="svg-inline--fa fa-users fa-w-20 h-48 lg--h-64"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                fill="currentColor"
                d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z"
              ></path>
            </svg>
          </div>
          <h3 class="font-bold pb-4 text-xl text-center">Redes Sociais</h3>
          <p class="text-justify">
            A gestão das redes sociais envolve muito mais que posts nas mesmas, implicando vários pontos como a definição de estratégias, o posicionamento da marca
            perante o seu público, calendarização de publicações, campanhas através de anúncios e, juntando a tudo isto, a monitorização de resultados. Gerimos as suas
            Redes Sociais e criamos estratégias para aumentar a sua visibilidade e os seus resultados.
          </p>
        </div>
        <div class="p-8 lg--w-1/3 mt-12">
          <div class="flex justify-center items-center mb-16">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="funnel-dollar"
              class="svg-inline--fa fa-funnel-dollar fa-w-20 h-48 lg--h-64"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                fill="currentColor"
                d="M433.46 165.94l101.2-111.87C554.61 34.12 540.48 0 512.26 0H31.74C3.52 0-10.61 34.12 9.34 54.07L192 256v155.92c0 12.59 5.93 24.44 16 32l79.99 60c20.86 15.64 48.47 6.97 59.22-13.57C310.8 455.38 288 406.35 288 352c0-89.79 62.05-165.17 145.46-186.06zM480 192c-88.37 0-160 71.63-160 160s71.63 160 160 160 160-71.63 160-160-71.63-160-160-160zm16 239.88V448c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-16.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V256c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v16.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.04 44.44-42.67 45.07z"
              ></path>
            </svg>
          </div>
          <h3 class="font-bold pb-4 text-xl text-center">Campanhas Online</h3>
          <p class="text-justify">
            Uma campanha de publicidade online é basicamente a forma através da qual uma entidade escolhe promover os seus produtos ou serviços através da web. A grande
            vantagem, comparativamente com outros tipos de publicidade convencionais, é o facto de existir a possibilidade de ser mais otimizada e altamente dirigida ao
            seu público-alvo (targets). As campanhas online podem ser realizadas através do Google Ads, direcionado para o website e nas Redes Sociais, através das várias
            redes que pretenda trabalhar.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Marketing",
  props: ["invertScrollClass"],
};
</script>

<style scoped></style>
