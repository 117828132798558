<template lang="pug">
	.cursor-pointer.fixed.h-screen.right-0.top-0.w-6.z-50.hidden.lg--block(
		@click="changePosition($event)"
		@mouseleave="untrackPosition"
		ref="scroll-bar")
		.absolute.h-8.right-0.rounded-lg.w-1-2(
			:class="'bg-gray-' + invertScrollClass + '00'"
			:style="scrollStyle"
			@mousedown="trackPosition"
			@mouseup="untrackPosition")
</template>

<script>
    export default {
        name: "Scrollbar",
        props: ['scrollY', 'invertScrollClass'],
        computed: {
            scrollStyle() {
                let scrollHeight = 100 - ((32 / window.innerHeight) * 100)
                let topPosition = (this.scrollY * 100) < scrollHeight ? (this.scrollY * 100) : scrollHeight
                return 'top: ' + topPosition + '%; opacity: 0.8'
            }
        },
        methods: {
            changePosition(event) {
                this.$emit('positionChanged', event.y)
            },
            trackPosition() {
                this.$refs['scroll-bar'].addEventListener('mousemove', this.changePosition);
            },
            untrackPosition() {
                this.$refs['scroll-bar'].removeEventListener('mousemove', this.changePosition);
            }
        }
    }
</script>

<style scoped>

</style>